import "./App.css";
import Hero from "./Hero/Hero";

export default function App() {
  return (
    <>
      <Hero />
    </>
  );
}
